var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mt-40"},[_c('div',{staticClass:"w50"},[(_vm.prevalence && _vm.prevalence.gam && _vm.prevalence.gam.length > 0)?_c('ResultItem',{attrs:{"title":_vm.$t('components.titles.prevalenceOfGlobalAcuteMalnutrition'),"subtext":_vm.$t('components.labels.valueAndOrEdema', {
        symbol: '<',
        number: '-2'
      }),"prevalence":_vm.prevalence.gam,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence || _vm.prevalence.gam && _vm.prevalence.gam.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineGlobalAcuteMalnutrition')}}):_vm._e(),(_vm.prevalence && _vm.prevalence.mam && _vm.prevalence.mam.length > 0)?_c('ResultItem',{staticClass:"mt-40",attrs:{"title":_vm.$t('components.titles.prevalenceOfModerateAcuteMalnutrition'),"subtext":_vm.$t('components.labels.greaterAndLessZScoresNoEdema', {
        symbol: '<',
        number: -2,
        symbolTwo: '>=',
        numberTwo: -3
      }),"prevalence":_vm.prevalence.mam,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence || _vm.prevalence.mam && _vm.prevalence.mam.length === 0)?_c('a-empty',{staticClass:"mt-40",attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineModerateAcuteMalnutrition')}}):_vm._e()],1),_c('div',{staticClass:"w50"},[(_vm.prevalence && _vm.prevalence.sam && _vm.prevalence.sam.length > 0)?_c('ResultItem',{attrs:{"title":_vm.$t('components.titles.prevalenceOfSevereAcuteMalnutrition'),"subtext":_vm.$t('components.labels.valueAndOrEdema', {
        symbol: '<',
        number: '-3'
      }),"prevalence":_vm.prevalence.sam,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence || _vm.prevalence.sam && _vm.prevalence.sam.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineSevereAcuteMalnutrition')}}):_vm._e(),(_vm.prevalence && _vm.prevalence.footer)?_c('ResultSummary',{staticClass:"mt-20",attrs:{"summary":_vm.prevalence.footer,"survey-info":_vm.surveyInfo,"type":_vm.type}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }