<template>
  <div class="flex mt-40">
    <div class="w50">
      <ResultItem
        v-if="prevalence && prevalence.gam && prevalence.gam.length > 0"
        :title="$t('components.titles.prevalenceOfGlobalAcuteMalnutrition')"
        :subtext="$t('components.labels.valueAndOrEdema', {
          symbol: '<',
          number: '-2'
        })"
        :prevalence="prevalence.gam"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.gam && prevalence.gam.length === 0"
        :description="$t('components.description.notEnoughDataToDetermineGlobalAcuteMalnutrition')"/>
      <ResultItem
        v-if="prevalence && prevalence.mam && prevalence.mam.length > 0"
        class="mt-40"
        :title="$t('components.titles.prevalenceOfModerateAcuteMalnutrition')"
        :subtext="$t('components.labels.greaterAndLessZScoresNoEdema', {
          symbol: '<',
          number: -2,
          symbolTwo: '>=',
          numberTwo: -3
        })"
        :prevalence="prevalence.mam"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.mam && prevalence.mam.length === 0"
        class="mt-40"
        :description="$t('components.description.notEnoughDataToDetermineModerateAcuteMalnutrition')"/>
    </div>
    <div class="w50">
      <ResultItem
        v-if="prevalence && prevalence.sam && prevalence.sam.length > 0"
        :title="$t('components.titles.prevalenceOfSevereAcuteMalnutrition')"
        :subtext="$t('components.labels.valueAndOrEdema', {
          symbol: '<',
          number: '-3'
        })"
        :prevalence="prevalence.sam"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.sam && prevalence.sam.length === 0"
        :description="$t('components.description.notEnoughDataToDetermineSevereAcuteMalnutrition')"/>
      <ResultSummary
        v-if="prevalence && prevalence.footer"
        :summary="prevalence.footer"
        :survey-info="surveyInfo"
        :type="type"
        class="mt-20"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnthropometryResultsPrevalanceWHZ',
  components: {
    ResultItem: () => import('../result-item'),
    ResultSummary: () => import('../result-summary')
  },
  props: {
    type: {
      type: String,
      default: undefined
    },
    prevalence: {
      type: Object,
      default: undefined
    },
    surveyInfo: {
      type: Object,
      default: undefined
    }
  }
};
</script>

<style lang="scss">

</style>
